var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',[(_vm.invoiceData.length > 0)?_c('v-card',[_c('v-row',{staticClass:"d-flex justify-space-between mb-1"},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-card-title',[_vm._v("Submitted Invoices for "+_vm._s(_vm.selectedInvoiceMonth))])],1),_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"auto"}},[_c('v-text-field',{staticClass:"mr-4",attrs:{"label":"Search","outlined":"","dense":"","hide-details":"auto"},model:{value:(_vm.tableSearch),callback:function ($$v) {_vm.tableSearch=$$v},expression:"tableSearch"}})],1)],1),_c('v-data-table',{attrs:{"search":_vm.tableSearch,"headers":_vm.submittedInvoiceHeaders,"items":_vm.invoiceData,"items-per-page":-1,"fixed-header":""},scopedSlots:_vm._u([{key:"item.orgName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.orgName)+" ")]}},{key:"item.unit",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.unit)+" ")]}},{key:"item.invoiceDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.invoiceDate ? _vm.formatDate(item.invoiceDate) : '')+" ")]}},{key:"item.submittedDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.submittedDate ? _vm.formatDate(item.submittedDate) : '')+" ")]}},{key:"item.ticketCount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.totals.ticketCount)+" ")]}},{key:"item.hours",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.totals.hours)+" ")]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.totals.total ? ("$" + (item.totals.total.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, }))) : '')+" ")]}},{key:"item.submitted",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({attrs:{"color":item.submitted ? 'success' : ''}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(item.submitted ? 'Yes' : 'No')+" ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(item.submittedBy && item.submittedBy.name ? ("Submittted by " + (item.submittedBy.name)) : 'Not Yet Submitted')+" ")])])]}},{key:"item.invoiceEmailed",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({class:(item.invoiceEmailed && item.submitted) || (!item.invoiceEmailed && !item.submitted)
                      ? 'markAsEmailedChip'
                      : '',attrs:{"color":item.invoiceEmailed && item.submitted
                      ? 'success'
                      : !item.invoiceEmailed && item.submitted
                      ? 'error'
                      : ''},on:{"click":function($event){return _vm.markInvoiceAsEmailed(item)}}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(item.invoiceEmailed ? 'Yes' : 'No')+" ")])]}}],null,true)},[_c('span',{staticStyle:{"max-width":"2rem !important"}},[_vm._v(_vm._s(item.invoiceEmailed && item.submitted ? 'Invoice has been emailed to JD&C' : !item.invoiceEmailed && item.submitted ? "This invoice has been submitted but it hasn't been emailed to JD&C. The email process may have failed when the invoice was submitted. You can download the invoice and manually email it to admin@jdcservices.ca and then click this badge to mark it as emailed." : 'Not Yet Submitted'))])])]}},{key:"item.docID",fn:function(ref){
                      var item = ref.item;
return [_vm._v(" "+_vm._s(item.docID)+" ")]}},{key:"item.invoicePdfUrl",fn:function(ref){
                      var item = ref.item;
return [_c('v-btn',{staticClass:"btn-download",attrs:{"small":"","color":"primary","disabled":!item.pdfURLs.generated},on:{"click":function($event){return _vm.downloadInvoicePDF(item, 'submitted')}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiFileDownload))])],1)]}}],null,true)})],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
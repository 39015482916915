<template>
  <div>
    <v-row>
      <v-col>
        <v-card v-if="invoiceData.length > 0">
          <v-row class="d-flex justify-space-between mb-1">
            <v-col cols="auto">
              <v-card-title>Submitted Invoices for {{ selectedInvoiceMonth }}</v-card-title>
            </v-col>

            <v-col cols="auto" class="d-flex align-center">
              <v-text-field
                class="mr-4"
                label="Search"
                v-model="tableSearch"
                outlined
                dense
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-data-table
            :search="tableSearch"
            :headers="submittedInvoiceHeaders"
            :items="invoiceData"
            :items-per-page="-1"
            fixed-header
          >
            <!-- Contractor -->
            <template #[`item.orgName`]="{ item }">
              {{ item.orgName }}
            </template>

            <!-- Unit -->
            <template #[`item.unit`]="{ item }">
              {{ item.unit }}
            </template>

            <!-- Invoice Date -->
            <template #[`item.invoiceDate`]="{ item }">
              {{ item.invoiceDate ? formatDate(item.invoiceDate) : '' }}
            </template>

            <!-- Submitted Date -->
            <template #[`item.submittedDate`]="{ item }">
              {{ item.submittedDate ? formatDate(item.submittedDate) : '' }}
            </template>

            <!-- Ticket Count -->
            <template #[`item.ticketCount`]="{ item }">
              {{ item.totals.ticketCount }}
            </template>

            <!-- Hours -->
            <template #[`item.hours`]="{ item }">
              {{ item.totals.hours }}
            </template>

            <!-- Total -->
            <template #[`item.total`]="{ item }">
              {{
                item.totals.total
                  ? `$${item.totals.total.toLocaleString('en-US', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}`
                  : ''
              }}
            </template>

            <!-- Submitted -->
            <template #[`item.submitted`]="{ item }">
              <v-tooltip top>
                <template #activator="{ on, attrs }">
                  <v-chip v-bind="attrs" v-on="on" :color="item.submitted ? 'success' : ''">
                    {{ item.submitted ? 'Yes' : 'No' }}
                  </v-chip>
                </template>
                <span>
                  {{
                    item.submittedBy && item.submittedBy.name
                      ? `Submittted by ${item.submittedBy.name}`
                      : 'Not Yet Submitted'
                  }}
                </span>
              </v-tooltip>
            </template>

            <!-- Emailed -->
            <template #[`item.invoiceEmailed`]="{ item }">
              <v-tooltip top>
                <template #activator="{ on, attrs }">
                  <v-chip
                    v-bind="attrs"
                    v-on="on"
                    @click="markInvoiceAsEmailed(item)"
                    :class="
                      (item.invoiceEmailed && item.submitted) || (!item.invoiceEmailed && !item.submitted)
                        ? 'markAsEmailedChip'
                        : ''
                    "
                    :color="
                      item.invoiceEmailed && item.submitted
                        ? 'success'
                        : !item.invoiceEmailed && item.submitted
                        ? 'error'
                        : ''
                    "
                  >
                    {{ item.invoiceEmailed ? 'Yes' : 'No' }}
                  </v-chip>
                </template>
                <span style="max-width: 2rem !important">{{
                  item.invoiceEmailed && item.submitted
                    ? 'Invoice has been emailed to JD&C'
                    : !item.invoiceEmailed && item.submitted
                    ? "This invoice has been submitted but it hasn't been emailed to JD&C. The email process may have failed when the invoice was submitted. You can download the invoice and manually email it to admin@jdcservices.ca and then click this badge to mark it as emailed."
                    : 'Not Yet Submitted'
                }}</span>
              </v-tooltip>
            </template>

            <!-- Invoice Num -->
            <template #[`item.docID`]="{ item }">
              {{ item.docID }}
            </template>

            <!-- Download Invoice Button -->
            <template #[`item.invoicePdfUrl`]="{ item }">
              <v-btn
                small
                color="primary"
                class="btn-download"
                @click="downloadInvoicePDF(item, 'submitted')"
                :disabled="!item.pdfURLs.generated"
              >
                <v-icon>{{ icons.mdiFileDownload }}</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mdiFileDownload } from '@mdi/js'
import { HalfCircleSpinner } from 'epic-spinners'
import firebase from 'firebase'
import lodash from 'lodash'
import moment from 'moment'

export default {
  components: {
    HalfCircleSpinner,
  },
  props: {
    submittedInvoices: Array,
    userData: Object,
    jdcAdmin: Boolean,
    selectedInvoiceMonth: String,
  },
  data() {
    return {
      icons: {
        mdiFileDownload,
      },
      submittedInvoiceHeaders: [
        { text: 'Contractor', value: 'orgName' },
        { text: 'Unit', value: 'unit' },
        { text: 'Invoice Date', value: 'invoiceDate' },
        { text: 'Submitted Date', value: 'submittedDate' },
        { text: 'Ticket Count', value: 'ticketCount' },
        { text: 'Hours', value: 'hours' },
        { text: 'Total', value: 'total' },
        { text: 'Submitted', value: 'submitted' },
        { text: 'Emailed', value: 'invoiceEmailed' },
        { text: 'Invoice', value: 'docID' },
        { text: 'Invoice PDF', value: 'invoicePdfUrl' },
      ],
      selectedCompany: sessionStorage.selectedOrg,
      orgData: null,
      rules: {
        required: value => !!value || 'Required.',
      },
      invoiceData: [],
      tableSearch: '',
    }
  },
  methods: {
    downloadInvoicePDF: async function (invoice, table) {
      try {
        if (table === 'unitsNotSubmitted') {
          if (!invoice || !invoice.invoicePdfUrl) {
            this.showError('Missing invoice PDF URL')
            return
          }
        } else {
          if (!invoice || !invoice.pdfURLs || !invoice.pdfURLs.generated) {
            this.showError('Missing invoice PDF URL')
            return
          }
        }

        const url = table === 'unitsNotSubmitted' ? invoice.invoicePdfUrl : invoice.pdfURLs.generated
        const response = await fetch(url)

        if (!response.ok) {
          this.showError('Failed to fetch the invoice PDF')
          throw new Error(`Failed to fetch the invoice PDF: ${response.statusText}`)
        }

        // convert the response to a Blob
        const blob = await response.blob()

        // create a URL for the Blob
        const blobUrl = URL.createObjectURL(blob)

        // create a temporary anchor to download the Blob
        const link = document.createElement('a')
        link.href = blobUrl
        link.download = `${invoice.docID}.pdf`

        // trigger the download
        link.click()

        // revoke the Blob URL after download
        URL.revokeObjectURL(blobUrl)
      } catch (error) {
        console.error('Error downloading the invoice PDF:', error)
        this.showError('Error downloading the invoice PDF')
      }
    },
    formatDate: function (date) {
      return moment(date.toDate()).format('MMM DD, YY')
    },
    showSuccess: function (message) {
      this.$toasted.show(message, {
        duration: '9000',
        keepOnHover: true,
        type: 'success',
      })
    },
    showError: function (message) {
      this.$toasted.show(message, {
        duration: '9000',
        keepOnHover: true,
        type: 'error',
      })
    },
    markInvoiceAsEmailed: async function (invoice) {
      if (!invoice.invoiceEmailed && invoice.submitted) {
        try {
          await firebase
            .firestore()
            .collection('orgs')
            .doc(invoice.orgDocID)
            .collection('invoices')
            .doc(invoice.docID)
            .update({ invoiceEmailed: true })

          // update local data
          let submittedInvoicesDataCopy = lodash.cloneDeep(this.submittedInvoices)

          const invoiceIndex = submittedInvoicesDataCopy.findIndex(
            x => x.orgDocID == invoice.orgDocID && x.docID == invoice.docID,
          )

          if (invoiceIndex !== -1) {
            submittedInvoicesDataCopy[invoiceIndex].invoiceEmailed = true
            this.$emit('updateInvoiceData', {
              updatedInvoiceData: submittedInvoicesDataCopy,
            })
          }

          this.showSuccess(`Marked invoice '${invoice.docID}' as emailed`)
        } catch (err) {
          console.log(`Error marking invoice '${invoice.docID}' as invoiced: ${err}`)
          this.showError(`Error marking invoice '${invoice.docID}' as invoiced`)
        }
      }
    },
  },
  watch: {
    submittedInvoices(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.invoiceData = this.submittedInvoices.map(invoice => ({
          ...invoice,
          ticketCount: Number(invoice.totals.ticketCount, 10) || 0,
          hours: Number(invoice.totals.hours) || 0,
          total: Number(invoice.totals.total) || 0,
        }))
      }
    },
  },
  mounted() {},
}
</script>

<style scoped>
.markAsEmailedChip {
  cursor: default !important;
}
</style>
